import { WsActionCompleted } from './server-payloads/ws-action-completed';
import { WsActionCreated } from './server-payloads/ws-action-created';
import { WsActionDetail } from './server-payloads/ws-action-detail';
import { WsActionV3Completed } from './server-payloads/ws-action-v3-completed';
import { WsActionV3Created } from './server-payloads/ws-action-v3-created';
import { WsClientFailure } from './server-payloads/ws-client-failure';
import { WsConnectionEstablished } from './server-payloads/ws-connection-established';
import { WsConnectionExpires } from './server-payloads/ws-connection-expires';
import { WsConnectionGone } from './server-payloads/ws-connection-gone';
import { WsIcCleared } from './server-payloads/ws-ic-cleared';
import { WsIcCuvUsedSyncCompleted } from './server-payloads/ws-ic-cuv-used-sync-completed';
import { WsIcCuvUsedSyncPending } from './server-payloads/ws-ic-cuv-used-sync-pending';
import { WsIcDeleted } from './server-payloads/ws-ic-deleted';
import { WsIcErrorOccurred } from './server-payloads/ws-ic-error-occurred';
import { WsIcSyncCompleted } from './server-payloads/ws-ic-sync-completed';
import { WsIcUpserted } from './server-payloads/ws-ic-upserted';
import { WsOperationCompleted } from './server-payloads/ws-operation-completed';
import { WsOperationCreated } from './server-payloads/ws-operation-created';
import { WsPong } from './server-payloads/ws-pong';
import { WsTaskCompleted } from './server-payloads/ws-task-completed';
import { WsTaskDetail } from './server-payloads/ws-task-detail';

// message schema for sending to clients
export interface WsServerMessageDto {
  // generated when the message is created
  messageId: string;
  createdTimeIso: string;

  // AWS lambda invocation ID that created this message
  requestCorrelationId: string;
  // the bulk operation FE session that caused this message, if known
  sessionCorrelationId?: string;
  // the bulk operation FE call that caused this message, if known
  callCorrelationId?: string;
  // message is caused by action taken by this user, if known
  originOdpUserId?: string;

  // message will be sent to clients using this company
  odpOrgId: string;

  payload: WsServerPayloadSchema;
}

export type WsServerPayloadSchema =
  // infrastructure
  | { type: WsServerPayloadEnum.ClientFailure; value: WsClientFailure }
  | { type: WsServerPayloadEnum.ConnectionEstablished; value: WsConnectionEstablished }
  | { type: WsServerPayloadEnum.ConnectionGone; value: WsConnectionGone }
  | { type: WsServerPayloadEnum.Pong; value: WsPong }
  | { type: WsServerPayloadEnum.ConnectionExpires; value: WsConnectionExpires }
  // actions
  | { type: WsServerPayloadEnum.ActionCreated; value: WsActionCreated }
  | { type: WsServerPayloadEnum.ActionDetail; value: WsActionDetail }
  | { type: WsServerPayloadEnum.ActionCompleted; value: WsActionCompleted }
  // actionsV3 for employee card edit
  | { type: WsServerPayloadEnum.EmployeeCreateActionCreated; value: WsActionV3Created }
  | { type: WsServerPayloadEnum.PositionCreateActionCreated; value: WsActionV3Created }
  | { type: WsServerPayloadEnum.EmployeeCardEditActionCreated; value: WsActionV3Created }
  | { type: WsServerPayloadEnum.EmployeeCreateActionCompleted; value: WsActionV3Completed }
  | { type: WsServerPayloadEnum.PositionCreateActionCompleted; value: WsActionV3Completed }
  | { type: WsServerPayloadEnum.EmployeeCardEditActionCompleted; value: WsActionV3Completed }
  // operations and tasks
  | { type: WsServerPayloadEnum.OperationCreated; value: WsOperationCreated }
  | { type: WsServerPayloadEnum.OperationCompleted; value: WsOperationCompleted }
  | { type: WsServerPayloadEnum.TaskDetail; value: WsTaskDetail }
  | { type: WsServerPayloadEnum.TaskCompleted; value: WsTaskCompleted }
  // item cache
  | { type: WsServerPayloadEnum.IcErrorOccurred; value: WsIcErrorOccurred }
  | { type: WsServerPayloadEnum.IcClearCompleted; value: WsIcCleared }
  | { type: WsServerPayloadEnum.IcSyncCompleted; value: WsIcSyncCompleted }
  | { type: WsServerPayloadEnum.IcUpserted; value: WsIcUpserted }
  | { type: WsServerPayloadEnum.IcCuvUsedSyncPending; value: WsIcCuvUsedSyncPending }
  | { type: WsServerPayloadEnum.IcCuvUsedSyncCompleted; value: WsIcCuvUsedSyncCompleted }
  | { type: WsServerPayloadEnum.IcDeleted; value: WsIcDeleted };

export enum WsServerPayloadEnum {
  // Client protocol failure detected. Most likely a bugfix is needed.
  ClientFailure = 'clientFailure',
  // Sent when any connection is established for this company.
  // Not guaranteed to be the first message in a new connection.
  ConnectionEstablished = 'connectionEstablished',
  // Sent when any other connection in this company is disconnect.
  ConnectionGone = 'connectionGone',
  // Sent in reply to ping.
  Pong = 'pong',
  // Sent in reply to ping when AWS would close the connection (2 hours) before the next ping.
  // The client must make a new connection before this is close, to avoid losing messages.
  ConnectionExpires = 'connectionExpires',

  // Sent when an action has been requested for the current organization.
  ActionCreated = 'actionCreated',
  // Debug information about an action (eg. handler started work, polling started, temporary error)
  ActionDetail = 'actionDetail',
  // Sent when an action has been completed (succeeded, failed or timed out).
  ActionCompleted = 'actionCompleted',

  // Sent when an employee card edit action has been requested for the current organization.
  EmployeeCreateActionCreated = 'employeeCreateActionCreated',
  PositionCreateActionCreated = 'positionCreateActionCreated ',
  EmployeeCardEditActionCreated = 'employeeCardEditActionCreated ',
  // Sent when an action has been completed (succeeded, failed or timed out).
  EmployeeCreateActionCompleted = 'employeeCreateActionCompleted',
  PositionCreateActionCompleted = 'positionCreateActionCompleted',
  EmployeeCardEditActionCompleted = 'employeeCardEditActionCompleted',

  // Sent when an operation has been requested for the current organization.
  OperationCreated = 'operationCreated',
  // Sent when an operation has been completed (succeeded, failed or timed out).
  OperationCompleted = 'operationCompleted',
  // Debug information about a task (eg. handler started work, polling started, temporary error)
  TaskDetail = 'taskDetail',
  // Sent when a task has been completed (succeeded, failed or timed out).
  TaskCompleted = 'taskCompleted',

  // Sent when any errors occur in the item cache handlers. It might be a transient error that is fixed again.
  IcErrorOccurred = 'icErrorOccurred',
  // Sent when the item icStartClear has completed.
  IcClearCompleted = 'icClearCompleted',
  // Sent when icStartSync has completed or a type that was missing in icLookup is synced for the first time.
  IcSyncCompleted = 'icSyncCompleted',
  // Sent when a single item -- or multiple items of same type -- is inserted or updated.
  IcUpserted = 'icUpserted',
  // Sent when a cost unit type has too many values to store in the lookup cache.
  // A separate sync will find active usages in positions and only cache those.
  // Await getting unknown cost unit value references until
  // IcCuvUsedSyncCompleted is received.
  IcCuvUsedSyncPending = 'icCuvUsedSyncPending',
  IcCuvUsedSyncCompleted = 'icCuvUsedSyncCompleted',
  IcDeleted = 'icDeleted',
}
