import { signal } from '@angular/core';
import {
  IIcLookupCostUnitValueItemDto,
  IcLookupCostUnitValueItemDto,
} from '@data-access/bulk-operations-api';

import { IcCostUnitValue, IcCostUnitValuesService } from '../services/ic-cost-unit-values.service';

const icCostUnitValuesForCu1: IcLookupCostUnitValueItemDto[] = [
  IcLookupCostUnitValueItemDto.fromJS({
    typeId: 'id-cu-1',
    valueId: 'id-cu-1-cuv-1',
    name: 'CU 1 Value 1',
  }),
  IcLookupCostUnitValueItemDto.fromJS({
    typeId: 'id-cu-1',
    valueId: 'id-cu-1-cuv-2',
    name: 'CU 1 Value 2',
  }),
];

const icCostUnitValuesForCu2: IcLookupCostUnitValueItemDto[] = [
  IcLookupCostUnitValueItemDto.fromJS({
    typeId: 'id-cu-2',
    valueId: 'id-cu-2-cuv-1',
    name: 'CU 2 Value 1',
  }),
  IcLookupCostUnitValueItemDto.fromJS({
    typeId: 'id-cu-2',
    valueId: 'id-cu-2-cuv-2',
    name: 'CU 2 Value 1',
  }),
];

// Should be synced with mapper in the service
function maToLookupModel(value: IIcLookupCostUnitValueItemDto): IcCostUnitValue {
  return {
    typeId: value.typeId,
    valueId: value.valueId,
    name: value.name,
    from: value.from,
    to: value.to,
    displayName: `${value.valueId} - ${value.name}`,
  };
}

export function getIcCostUnitValuesServiceMock() {
  return {
    isInitialized: signal(true),
    loadingTypes: signal(new Set<string>()),
    valuesMapByType: signal(
      new Map<string, Map<string, IcCostUnitValue>>([
        [
          'id-cu-1',
          new Map<string, IcCostUnitValue>(
            icCostUnitValuesForCu1.map((item) => [item.valueId, maToLookupModel(item)]),
          ),
        ],
        [
          'id-cu-2',
          new Map<string, IcCostUnitValue>(
            icCostUnitValuesForCu2.map((item) => [item.valueId, maToLookupModel(item)]),
          ),
        ],
      ]),
    ),
    valuesArrayByType: signal(
      new Map<string, IcCostUnitValue[]>([
        ['id-cu-1', icCostUnitValuesForCu1.map((item) => maToLookupModel(item))],
        ['id-cu-2', icCostUnitValuesForCu2.map((item) => maToLookupModel(item))],
      ]),
    ),
  } as Partial<IcCostUnitValuesService>;
}
