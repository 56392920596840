import { IcLookupEventCodeForAbsenceRegistrationItemDto } from '@data-access/bulk-operations-api';
import { getTranslationFromLocalizedString } from '@em-helpers';

import { IcEventCodeForAbsenceRegistration } from '../services/ic-event-codes-for-absence.service';
import { createIcServiceMock } from './item-cache-service.base.mock';

const icEventCodesForAbsenceRegistration: IcLookupEventCodeForAbsenceRegistrationItemDto[] = [
  IcLookupEventCodeForAbsenceRegistrationItemDto.fromJS({
    id: '0542c83f-1e5e-4980-87a4-e927d12bde9a',
    data: {
      name: { english: 'Absence', norwegian: 'Absence No' },
      externalIds: ['11'],
    },
  }),
  IcLookupEventCodeForAbsenceRegistrationItemDto.fromJS({
    id: '14884f9f-217e-40da-a83c-47c2cb4f19ac',
    data: {
      name: {
        english: 'Educational leave - Statutory (paid)',
        norwegian: 'Educational leave - Statutory (paid) NO',
      },
      externalIds: ['21'],
    },
  }),
];

function mapToLookupModel(
  value: IcLookupEventCodeForAbsenceRegistrationItemDto,
): IcEventCodeForAbsenceRegistration {
  return {
    ...value.data,
    name: getTranslationFromLocalizedString(value.data.name),
    id: value.id,
  };
}

export function getIcEventCodesForAbsenceRegistrationServiceMock() {
  return createIcServiceMock(
    icEventCodesForAbsenceRegistration.map(mapToLookupModel),
    icEventCodesForAbsenceRegistration,
  );
}
