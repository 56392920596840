// - `Active`: There is a position active today.
// - `Inactive`: There is no position active today or in the future.
// - `Future`: There is no position active today, but a position that will become active in the future.
// - `Draft`: The employee API returns `isDraft=true` for this employee.
export enum EmployeeStatusEnum {
  Active = 'active',
  Inactive = 'inactive',
  Future = 'future',
  Draft = 'draft',
}
