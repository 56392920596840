import { Injectable, computed, inject, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import {
  CostUnitTypeValuesResponseDto,
  CostUnitsClient,
  IIcLookupCostUnitValueItemDto,
  IcLookupCostUnitValueItemDto,
  IcLookupTypeEnumDto,
  ItemCacheClient,
} from '@data-access/bulk-operations-api';
import { WebSocketClient, WsServerPayloadEnum } from '@data-access/bulk-operations-ws';
import {
  EMPTY,
  Observable,
  Subject,
  catchError,
  delayWhen,
  expand,
  filter,
  iif,
  map,
  of,
  reduce,
  switchMap,
  take,
  tap,
} from 'rxjs';

import { lookupReducer } from '../helpers/lookup-reducer';

@Injectable({ providedIn: 'root' })
export class IcCostUnitValuesService {
  protected readonly client = inject(ItemCacheClient);
  protected readonly wsClient = inject(WebSocketClient);
  protected readonly costUnitsClient = inject(CostUnitsClient);

  public readonly isInitialized = signal(false);
  protected readonly isInitialized$ = toObservable(this.isInitialized);

  private isManualSync = false;
  private readonly loadingTypesSignal = signal<Set<string>>(new Set());
  public readonly loadingTypes = this.loadingTypesSignal.asReadonly();

  private readonly directFetchSearches = new Map<string, string[]>();
  private readonly searchSubject = new Subject<{ typeId: string; search: string }>();

  private readonly _valuesMapByType = signal<Map<string, Map<string, IcCostUnitValue>>>(new Map());
  public readonly valuesMapByType = this._valuesMapByType.asReadonly();
  public readonly valuesArrayByType = computed(() => {
    const cuvArrayByType = new Map<string, IcCostUnitValue[]>();
    this._valuesMapByType().forEach((cuvMap, typeId) => {
      const cuvArray = Array.from(cuvMap.values());
      const sortedArray = cuvArray.sort((a, b) =>
        a.valueId.localeCompare(b.valueId, undefined, { numeric: true }),
      );
      cuvArrayByType.set(typeId, sortedArray);
    });
    return cuvArrayByType;
  });

  constructor() {
    this.subscribeToWsSyncComplete();
    this.subscribeToWsUpsert();
    this.subscribeToWsDelete();
    this.subscribeToDirectFetchSearches();
  }

  private maToLookupModel(value: IIcLookupCostUnitValueItemDto): IcCostUnitValue {
    return {
      ...value,
      displayName: value.isGhost
        ? $localize`${value.valueId} - [Not Found]`
        : `${value.valueId} - ${value.name}`,
    };
  }

  public setCache(lookupValues: IIcLookupCostUnitValueItemDto[], isInitialization = false) {
    const cuvByTypeMap = new Map<string, Map<string, IcCostUnitValue>>();

    lookupValues
      .map((item) => this.maToLookupModel(item))
      .forEach((item) => {
        let cuvForType = cuvByTypeMap.get(item.typeId);
        if (!cuvForType) {
          cuvByTypeMap.set(item.typeId, new Map<string, IcCostUnitValue>());
          cuvForType = cuvByTypeMap.get(item.typeId);
        }

        cuvForType?.set(item.valueId, item);
      });

    this._valuesMapByType.set(cuvByTypeMap);

    if (!isInitialization) {
      this.isInitialized.set(true);
    }
  }

  public upsertCacheItem(newValue: IIcLookupCostUnitValueItemDto) {
    this.upsertCacheItems([newValue]);
  }

  public upsertCacheItems(newValues: IIcLookupCostUnitValueItemDto[]) {
    const cuvMapByType = this._valuesMapByType();

    newValues
      .map((item) => this.maToLookupModel(item))
      .forEach((item) => {
        let cuvForType = cuvMapByType.get(item.typeId);
        // Ensure CU is created
        if (!cuvForType) {
          cuvMapByType.set(item.typeId, new Map<string, IcCostUnitValue>());
          cuvForType = cuvMapByType.get(item.typeId);
        }

        cuvForType?.set(item.valueId, item);
      });

    this._valuesMapByType.set(new Map(cuvMapByType));
  }

  public removeCacheItems(ids: { id: string; id2?: string }[]) {
    const cuvMapByType = this._valuesMapByType();

    ids.forEach((item) => {
      if (!item.id2) {
        cuvMapByType.delete(item.id);
      } else {
        const cuvForType = cuvMapByType.get(item.id);
        if (!cuvForType) {
          return;
        }

        cuvForType.delete(item.id2);
      }
    });

    this._valuesMapByType.set(new Map(cuvMapByType));
  }

  public removeCacheItem(typeId: string, costUnitValueId?: string) {
    this.removeCacheItems([{ id: typeId, id2: costUnitValueId }]);
  }

  public startSync(typeId: string) {
    this.client
      .getIcStartSync(IcLookupTypeEnumDto.Cuv, typeId)
      .pipe(take(1))
      .subscribe((resp) => {
        this.isManualSync = true;
      });
  }

  public fetchValues(): Observable<IcLookupCostUnitValueItemDto[]> {
    const observable$ = this.client.getIcLookup(IcLookupTypeEnumDto.Cuv).pipe(
      take(1),
      expand((response) =>
        response.lookup.nextToken
          ? this.client.getIcLookup(IcLookupTypeEnumDto.Cuv, response.lookup.nextToken)
          : EMPTY,
      ),
      reduce(lookupReducer),
      map((response) => response.lookup.costUnitValues),
    );

    observable$.subscribe((data) => {
      this.setCache(data);
    });

    return observable$;
  }

  protected subscribeToWsUpsert() {
    this.wsClient
      .getMessagesByType(WsServerPayloadEnum.IcUpserted)
      .pipe(
        filter((msg) => msg.type === IcLookupTypeEnumDto.Cuv),
        // Hold processing to avoid overwrites
        delayWhen(() =>
          iif(
            () => this.isInitialized(),
            of(true),
            this.isInitialized$.pipe(filter((isInitialized) => isInitialized)),
          ),
        ),
      )
      .subscribe((msg) => {
        this.upsertCacheItems(msg.items);
      });
  }

  private subscribeToWsSyncComplete() {
    this.wsClient
      .getMessagesByType(WsServerPayloadEnum.IcSyncCompleted)
      .pipe(filter((msg) => msg.type === IcLookupTypeEnumDto.Cuv))
      .subscribe((msg) => {
        if (msg.isInitialization || this.isManualSync) {
          this.fetchValues();
        }
      });
  }

  private subscribeToWsDelete() {
    this.wsClient
      .getMessagesByType(WsServerPayloadEnum.IcDeleted)
      .pipe(
        filter((msg) => msg.type === IcLookupTypeEnumDto.Cuv),
        // Hold processing to avoid overwrites
        delayWhen(() =>
          iif(
            () => this.isInitialized(),
            of(true),
            this.isInitialized$.pipe(filter((isInitialized) => isInitialized)),
          ),
        ),
      )
      .subscribe((msg) => {
        this.removeCacheItems(msg.items);
      });
  }

  private subscribeToDirectFetchSearches() {
    this.searchSubject
      .pipe(switchMap(({ typeId, search }) => this.executeDirectFetch(typeId, search)))
      .subscribe((data) => {
        this.upsertCacheItems(data);
      });
  }

  public directSearchForValues(typeId: string, search: string) {
    this.searchSubject.next({ typeId, search });
  }

  private executeDirectFetch(typeId: string, search: string) {
    const searchesForType = this.directFetchSearches.get(typeId);
    if (searchesForType?.some((prevSearch) => search.includes(prevSearch))) {
      return of([]);
    }

    this.setTypeLoading(typeId, true);
    return this.costUnitsClient.getCostUnitTypeValues(typeId, search).pipe(
      expand((response) => {
        return response.valuesForType?.cursor
          ? this.costUnitsClient.getCostUnitTypeValues(
              typeId,
              undefined,
              response.valuesForType.cursor,
            )
          : EMPTY;
      }),
      reduce((acc, value) => {
        if (!acc || !acc.valuesForType) return value;

        acc.valuesForType.costUnitValues = acc.valuesForType.costUnitValues.concat(
          value.valuesForType?.costUnitValues ?? [],
        );

        return acc;
      }),
      take(1),
      map((response) => this.mapDirectResponseToIcDto(response)),
      tap(() => {
        this.setTypeLoading(typeId, false);
        searchesForType
          ? searchesForType.push(search)
          : this.directFetchSearches.set(typeId, [search]);
      }),
      catchError(() => {
        this.setTypeLoading(typeId, false);
        return EMPTY;
      }),
    );
  }

  private mapDirectResponseToIcDto(
    response: CostUnitTypeValuesResponseDto,
  ): IIcLookupCostUnitValueItemDto[] {
    if (!response.valuesForType) {
      return [];
    }
    const typeId = response.valuesForType.typeCustomId;

    return response.valuesForType.costUnitValues.map((value) => {
      return {
        typeId,
        valueId: value.customId,
        name: value.name,
        displayName: `${value.customId} - ${value.name}`,
        from: value.from,
        to: value.to,
      };
    });
  }

  private setTypeLoading(typeId: string, isLoading: boolean) {
    this.loadingTypesSignal.update((loadingTypes) => {
      if (isLoading) {
        loadingTypes.add(typeId);
      } else {
        loadingTypes.delete(typeId);
      }

      return new Set(loadingTypes);
    });
  }
}

export type IcCostUnitValue = IIcLookupCostUnitValueItemDto & { displayName: string };
