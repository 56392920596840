import { signal } from '@angular/core';
import { IcLookupSettingsDto } from '@data-access/bulk-operations-api';

const icService: IcLookupSettingsDto = IcLookupSettingsDto.fromJS({
  enableSetOfAccount: true,
  eventCodesForArConfigUrl:
    'https://web.calendar.hrm.stag.visma.net/absence/#/88452dc4-9a74-4f8b-96c0-d2a648ba6485/settings/fileimport',
});

export function getIcSettingsServiceMock() {
  return {
    isInitialized: signal(true),
    value: signal(icService),
  };
}
