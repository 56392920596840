import { IcLookupPaycodeForFixedTransactionItemDto } from '@data-access/bulk-operations-api';
import { getTranslationFromLocalizedString } from '@em-helpers';

import { IcPaycodeForFixedTransaction } from '../services/ic-paycodes-for-fixed-transaction.service';
import { createIcServiceMock } from './item-cache-service.base.mock';

export const icPaycodesForFixedTransactionsMock: IcLookupPaycodeForFixedTransactionItemDto[] = [
  IcLookupPaycodeForFixedTransactionItemDto.fromJS({
    data: {
      name: {
        english: 'Life/group insurance (taxable)',
        norwegian: 'Livsforsikring/gruppeliv (trekkpliktig)',
      },
      description: { english: 'Insurance, liable for tax', norwegian: '' },
      fields: {
        quantity: {
          isAllowed: false,
          isRequired: false,
        },
        factor: {
          isAllowed: false,
          isRequired: false,
        },
        price: {
          isAllowed: true,
          isRequired: false,
        },
        totalPrice: {
          isAllowed: false,
          isRequired: false,
        },
      },
      timeline: [
        {
          value: {
            additionalInfo: [],
          },
        },
      ],
      customId: '14100',
      displayName: '14100 - Life/group insurance (taxable)',
    },
    id: '857b1faf-a457-84a8-cd12-51ee5820daf5',
  }),
  IcLookupPaycodeForFixedTransactionItemDto.fromJS({
    data: {
      name: {
        english: 'Various fixed deductions',
        norwegian: 'Diverse fast trekk',
      },
      description: { english: 'Miscellaneous deductions from wages.', norwegian: '' },
      fields: {
        quantity: {
          isAllowed: false,
          isRequired: false,
        },
        factor: {
          isAllowed: false,
          isRequired: false,
        },
        price: {
          isAllowed: false,
          isRequired: false,
        },
        totalPrice: {
          isAllowed: true,
          isRequired: true,
        },
      },
      timeline: [
        {
          value: {
            additionalInfo: [],
          },
        },
      ],
      customId: '63100',
      displayName: '63100 - Various fixed deductions',
    },
    id: '62fb56ed-b857-84a8-cd12-51ee635edaf5',
  }),
  IcLookupPaycodeForFixedTransactionItemDto.fromJS({
    data: {
      name: {
        english: 'Fixed addition',
        norwegian: 'Faste tillegg',
      },
      description: {
        english: 'Can be used for fixed additions. For example fixed extra pay for managers.',
        norwegian: '',
      },
      fields: {
        quantity: {
          isAllowed: false,
          isRequired: false,
        },
        factor: {
          isAllowed: false,
          isRequired: false,
        },
        price: {
          isAllowed: false,
          isRequired: false,
        },
        totalPrice: {
          isAllowed: true,
          isRequired: true,
        },
      },
      timeline: [
        {
          value: {
            additionalInfo: [],
          },
        },
      ],
      customId: '10050',
      displayName: '10050 - Fixed addition',
    },
    id: 'd47e8840-302d-8014-0d15-527361ce2be5',
  }),
];

function mapToLookupModel(
  value: IcLookupPaycodeForFixedTransactionItemDto,
): IcPaycodeForFixedTransaction {
  return {
    ...value.data,
    description: getTranslationFromLocalizedString(value.data.description),
    displayName: `${value.data.customId} - ${getTranslationFromLocalizedString(value.data.name)}`,
    id: value.id,
  };
}

export function getIcPaycodesForFixedTransactionServiceMock() {
  return createIcServiceMock(
    icPaycodesForFixedTransactionsMock.map(mapToLookupModel),
    icPaycodesForFixedTransactionsMock,
  );
}
