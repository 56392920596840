import { Injectable, computed } from '@angular/core';
import {
  IIcLookupPaycodeForFixedTransactionDataDto,
  IcLookupDto,
  IcLookupPaycodeForFixedTransactionItemDto,
  IcLookupTypeEnumDto,
} from '@data-access/bulk-operations-api';
import { getTranslationFromLocalizedString } from '@em-helpers';

import { IcServiceBase } from './ic-service.base';

@Injectable({ providedIn: 'root' })
export class IcPaycodesForFixedTransactionService extends IcServiceBase<
  IcLookupPaycodeForFixedTransactionItemDto,
  IcPaycodeForFixedTransaction
> {
  protected override lookupType = IcLookupTypeEnumDto.PaycodeForFt;

  protected override mapToLookupModel(
    value: IcLookupPaycodeForFixedTransactionItemDto,
  ): IcPaycodeForFixedTransaction {
    return {
      ...value.data,
      description: getTranslationFromLocalizedString(value.data.description),
      displayName: `${value.data.customId} - ${getTranslationFromLocalizedString(value.data.name)}`,
      id: value.id,
    };
  }

  protected override getValuesFromLookupDto(
    lookup: IcLookupDto,
  ): IcLookupPaycodeForFixedTransactionItemDto[] {
    return lookup.paycodesForFixedTransaction;
  }

  public override readonly values = computed<IcPaycodeForFixedTransaction[]>(() =>
    [...this.rawValues().values()]
      .map((item) => this.mapToLookupModel(item))
      .sort((a, b) => a.customId.localeCompare(b.customId, undefined, { numeric: true })),
  );
}

export type IcPaycodeForFixedTransaction = Omit<
  IIcLookupPaycodeForFixedTransactionDataDto,
  'description'
> & {
  id: string;
  displayName: string;
  description: string;
};
