import { IcLookupCostUnitTypeItemDto } from '@data-access/bulk-operations-api';

import { IcCostUnitType } from '../services/ic-cost-unit-types.service';
import { createIcServiceMock } from './item-cache-service.base.mock';

const icCostUnitTypes: IcLookupCostUnitTypeItemDto[] = [
  IcLookupCostUnitTypeItemDto.fromJS({
    data: {
      name: 'Name CU #1',
      hasMore: false,
    },
    id: 'id-cu-1',
  }),
  IcLookupCostUnitTypeItemDto.fromJS({
    data: {
      name: 'Name CU #2',
      hasMore: false,
    },
    id: 'id-cu-2',
  }),
];

// Should be synced with mapper in the service
function mapToLookupModel(value: IcLookupCostUnitTypeItemDto): IcCostUnitType {
  return {
    ...value.data,
    id: value.id,
    displayName: `${value.id} - ${value.data.name}`,
  };
}

export function getIcCostUnitTypesServiceMock() {
  return createIcServiceMock(icCostUnitTypes.map(mapToLookupModel), icCostUnitTypes);
}
